import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Group Informed Consensus`}</h1>
    <p>{`Group informed consensus is a measure of the extent to which an `}<a parentName="p" {...{
        "href": "/opinion-group",
        "title": "opinion group"
      }}>{`opinion group`}</a>{` in the `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` agrees (by `}<a parentName="p" {...{
        "href": "/vote",
        "title": "vote"
      }}>{`vote`}</a>{`) in response to a particular `}<a parentName="p" {...{
        "href": "/comment",
        "title": "comment"
      }}>{`comment`}</a>{`.
It is designed to counter "tyranny of the majority" by optimizing for comments which are as favorably viewed as possible across `}<a parentName="p" {...{
        "href": "/participant",
        "title": "participant"
      }}>{`participant`}</a>{` `}<a parentName="p" {...{
        "href": "/group",
        "title": "group"
      }}>{`group`}</a>{`s in the conversation.`}</p>
    <h2>{`Derivation`}</h2>
    <p>{`For any `}<a parentName="p" {...{
        "href": "/group",
        "title": "group"
      }}>{`group`}</a>{` of `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{`, you can estimate the probability that a random participant drawn from that group will agree with a particular `}<a parentName="p" {...{
        "href": "/comment",
        "title": "comment"
      }}>{`comment`}</a>{` by counting up the number of agrees, and dividing by the total number of votes.`}</p>
    <p>{`It can be helpful to add 1 to the first of these numbers, and 2 to the second, so that in the absence of any vote data, our estimate defaults to 1/2.
These "`}<a parentName="p" {...{
        "href": "/psuedocounts",
        "title": "psuedocounts"
      }}>{`psuedocounts`}</a>{`" act as a naive prior to smooth out the estimates for low vote data, and importantly prevent the system from dividing by zero.`}</p>
    <p>{`If one multiplies these estimated probabilities for each group in the `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{`, we get a number that will be highest for `}<a parentName="p" {...{
        "href": "/comment",
        "title": "comment"
      }}>{`comment`}</a>{`s every `}<a parentName="p" {...{
        "href": "/group",
        "title": "group"
      }}>{`group`}</a>{` tends to agree with.
We call this product the `}<em parentName="p">{`group informed consensus metric`}</em>{`.`}</p>
    <h2>{`Intuition`}</h2>
    <p>{`This metric is designed so that even a single small group united in opposition against a comment can prevent it from having high group informed consensus, even if all the other groups are in unanimous support.
High group informed consensus is more likely going to select for an option with 70-80% favorability across all groups than for a couple of large opinion groups to simply have their way on an issue (tyranny of the majority).`}</p>
    <h2>{`Discussion`}</h2>
    <p>{`It's important to note that this metric can vary significantly based on what the chosen `}<a parentName="p" {...{
        "href": "/opinion-groups",
        "title": "opinion groups"
      }}>{`opinion groups`}</a>{` look like.
This highlights the importance of carefully considering what `}<a parentName="p" {...{
        "href": "/clustering-algorithms",
        "title": "clustering algorithms"
      }}>{`clustering algorithms`}</a>{` we use to identify `}<a parentName="p" {...{
        "href": "/opinion-groups",
        "title": "opinion groups"
      }}>{`opinion groups`}</a>{` from the vote data, and suggests an angle for considering how trade offs between different `}<a parentName="p" {...{
        "href": "/clustering-algorithms",
        "title": "clustering algorithms"
      }}>{`clustering algorithms`}</a>{` are evaluated.`}</p>
    <ul>
      <li parentName="ul">{`See: `}<a parentName="li" {...{
          "href": "/consensus",
          "title": "consensus"
        }}>{`consensus`}</a>{`, `}<a parentName="li" {...{
          "href": "/representative-comments",
          "title": "representative comments"
        }}>{`representative comments`}</a>{`, `}<a parentName="li" {...{
          "href": "/opinion-groups",
          "title": "opinion groups"
        }}>{`opinion groups`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      